.block1 {
    position: relative;
    float: left;
    overflow: hidden;
    background: blue;
    height: 200px;
    width: 200px;
}

#element {
    position: relative;
    float: left;
    overflow: hidden;
    background: green;
    height: 200px;
    width: 200px;
}

